var columns = [{
  title: 'UID',
  dataIndex: 'userid',
  key: 'userid',
  // width: '5%',
  scopedSlots: {
    customRender: 'userid'
  }
}, {
  title: '用户名称',
  dataIndex: 'userName',
  key: 'userName',
  // width: '25%',
  scopedSlots: {
    customRender: 'userName'
  }
}, {
  title: '邮箱',
  dataIndex: 'userEmail',
  key: 'userEmail',
  // width: '5%',
  scopedSlots: {
    customRender: 'userEmail'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '订单号',
  dataIndex: 'orderno',
  key: 'orderno',
  // width: '5%',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '第三方流水号',
  dataIndex: 'tradeno',
  key: 'tradeno',
  // width: '5%',
  scopedSlots: {
    customRender: 'tradeno'
  }
}, {
  title: '鉴定币(个)',
  dataIndex: 'toknes',
  key: 'toknes',
  // width: '10%',
  scopedSlots: {
    customRender: 'toknes'
  }
},
// {
//     title: '优惠($))',
//     dataIndex: '',
//     key: '',
//     // width: '10%',
//     scopedSlots: {
//         customRender: ''
//     }
// },
{
  title: '支付金额($)',
  dataIndex: 'amount',
  key: 'amount',
  // width: '5%',
  scopedSlots: {
    customRender: 'amount'
  }
}, {
  title: '支付方式',
  dataIndex: 'typeName',
  key: 'typeName',
  // width: '5%',
  scopedSlots: {
    customRender: 'typeName'
  }
}, {
  title: '支付时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '5%',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns };