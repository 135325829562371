var searchKeys = [{
  key: 'dates',
  label: '下单时间',
  format: "YYYY-MM-DD HH:mm:ss",
  placeholder: ["开始时间", "截止时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "orderNo",
  label: "订单号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "tradeNo",
  label: "第三方流水号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "uid",
  label: "UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "user",
  label: "用户",
  placeholder: "昵称/邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };